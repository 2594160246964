import React from 'react';

import './style.scss';

import bbalogowhite from '../images/BBA_Logo_tag-WHITE.svg';
import bbalogoinset from '../images/BBA_Logo-inset.png';

const Homeheader = ({ siteTitle }) => (
	<section className="hero homehero">
		<div className="hero-body">
			<div className="container">
				<div className="columns is-vcentered is-mobile">
					<div className="column">
					<img className="homelogo" src={bbalogowhite} alt={siteTitle} />
					<hr className="earthwarebg shortbar"></hr>
					<h2 className="mont is-size-5 is-size-6-mobile is-uppercase stone tracked has-text-weight-bold">Brand Standards</h2>
					</div>
					<div className="column is-hidden-mobile">
					<img className="insethome is-pulled-right" src={bbalogoinset} alt="bba-logo-inset" />
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default Homeheader;
