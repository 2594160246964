import React from 'react';

import './style.scss';
import Homeheader from './homeheader';
import Homepage from './homepage';
import Footnav from './footnav'
import Footer from './footer';

const HomeLayout = ({ children }) => (
	<div>
		<Homeheader />
		<Homepage />
		<Footer />
	</div>
);

export default HomeLayout;
