import React from 'react';
import { Link } from 'gatsby';
import homeicon from '../images/home.svg';
import rarr from '../images/rarr.svg';
//import larr from '../images/larr.svg';
import './style.scss';
import Navbar from './navbar.js';
import titleicon from '../images/ticon.svg';
import angelimg from '../images/angel.jpg';
import fig1 from '../images/fig1.png';
import fig2 from '../images/fig2.png';
import fig3 from '../images/fig3.png';
import fig4 from '../images/fig4.png';
import fig5 from '../images/fig5.png';
import fig6 from '../images/fig6.png';

var whostyle = {
	backgroundImage: `url(${angelimg})`
}

const Homecontent = () => (
	<div>
		<div className="container">
			<div className="navhelp homenav">
				<Navbar />
			</div>
		</div>
		<section className="section homewho">
			<div className="container">
				<div className="columns">
					<div className="column is-6 pb-6">
						<h4 className="vertalign iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">
						<img className="titleicon" src={titleicon} alt="title icon" /> Who We Are
						</h4>
						<h2 className="is-size-1 capitol lineheight1 steel">Making tomorrow<br/> a better <em>place</em>.</h2>
						<p>We believe in the power of transformation. We believe that by shaping objects and the experiences around those objects we’re capable of improving lives, neighborhoods and communities. While change is inevitable it can also be innovative, creative, and productive—all that’s required is a nuturing hand. We shape places and, in turn, they shape us.</p>
					</div>
					<div className="column is-6">
						<div className="herobg" style={whostyle}></div>
					</div>
				</div>
			</div>
		</section>

		<section className="homelegacy pbt-section-tiny">
			<div className="container">
				<div className="columns is-centered">
					<div className="column">
						<div className="box is-radiusless raised whitebg">
					<h2 className="is-size-1 capitol lineheight1 steel has-text-centered">Our Legacy</h2>
						<p>Baker Barrios Architects has a long-standing commitment to values and ideals that will forever be imprinted on our legacy and woven into the very DNA of the company. We are in the business of service. Through our commitment to meaningful relationships, both internal and external, we are proud to produce creative work that inspires communities around us while making lives better. That’s how we started and that’s how we’ll continue to grow. Some things – like our values and ideals – should never change.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="section">
			<div className="container pb-6">
			<h2 className="is-size-1 capitol lineheight1 steel">Core Values</h2>
			<div className="columns">
				<div className="column is-half pb-6">
					<p>Core values are a list of strongly held beliefs and ideals used to define the essence of our company.</p>
				</div>
			</div>
			<div className="columns is-size-5 capitol stone">
				<div className="column is-one-third py-0">
					<ul className="has-bullets">
					<li>Team/Collaboration</li>
					<li>Innovation</li>
					<li>Integrity</li>
					<li>Quality Work</li>
					<li>Storytelling</li>
					</ul>
				</div>
				<div className="column is-one-third py-0">
					<ul className="has-bullets">
					<li>Sustainability</li>
					<li>Wellness</li>
					<li>Collegiality</li>
					<li>Serving our Clients</li>
					<li>Helping our Community</li>
					</ul>
				</div>
				<div className="column is-one-third py-0">
					<ul className="has-bullets">
					<li>A Nurturing Environment</li>
					<li>Family: At home and at work</li> 
					<li>Stewardship</li>
					</ul>
				</div>
			</div>
			</div>
		</section>

		<section className="homeculture whitebg py-10 section">
			<div className="container">
			<div className="columns is-centered">
				<div className="column is-5 has-text-centered">
				<h2 className="is-size-1 capitol lineheight1 steel">Cultural Norms</h2>
				<p>Distinctive behaviors that are idealized, modeled, rewarded, and encouraged company wide.</p>
				</div>
			</div>
			<div className="columns is-multiline has-text-centered align-end">
				<div className="column is-one-third">
					<img className="fig1" src={fig1} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">Realizing Potential</h4>
				</div>
				<div className="column is-one-third">
					<img className="fig2" src={fig2} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">Empty Your Cup</h4>
				</div>
				<div className="column is-one-third">
					<img className="fig3" src={fig3} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">Serve Others</h4>
				</div>
				<div className="column is-one-third">
					<img className="fig4" src={fig4} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">Always Mentor</h4>
				</div>
				<div className="column is-one-third">
					<img className="fig5" src={fig5} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">We Grow in Width</h4>
				</div>
				<div className="column is-one-third">
					<img className="fig6" src={fig6} alt="title icon" />
					<h4 className="deepsea capitol is-size-4">Make the Invisible, Visible</h4>
				</div>
			</div>
			</div>
		</section>
		<div className="spacer py-6"></div>

		<div className='footer-nav'>
			<nav className='navbarfoot'>
				<div className='container spotgrid'>
					<span></span>
					<div className="has-text-centered">
					<Link className='homebut' to='/'>
						<img src={homeicon} alt='Home' />
					</Link>
					</div>
					<Link className='nextpage' to='/brand'>
						Brand <img src={rarr} alt='Right Arrow' />
					</Link>
				</div>
			</nav>
		</div>

	</div>
);

export default Homecontent;
